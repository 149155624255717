export const petData = [
  { 
    key : "Race",
    value : "Alien"
  },
  { 
    key : "Weight",
    value : "2.8kg"
  },
  { 
    key : "Height",
    value : "0.21m"
  },
]