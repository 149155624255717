import PostForm from "../components/post/PostForm";

const PostingPage = () => {
  return (
    <>
      <PostForm></PostForm>
    </>
  );
};

export default PostingPage;
