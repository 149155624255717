import Pet from "../components/pet/Pet"
import Navbar from "../components/common/Navbar";

const petPage = () => {

  return (
    <>
      <Pet/>
      <Navbar/>
    </>
  );
}

export default petPage;



