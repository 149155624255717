import EditForm from "../edit/EditForm";

const EditingPage = () => {
  return (
    <>
      <EditForm></EditForm>
    </>
  );
};

export default EditingPage;
